import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { decryptData, encryptData } from '../crypto-js/crypto';
import axios from 'axios';
const Header = ({ username, coins, companyname, role }) => {


  console.log('transporterrole',role)
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const toggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };
  const handleDash = () =>{
    console.log('gggg',role)
    const encryptedRole = encryptData(role);
  localStorage.setItem("pair", encryptedRole);
    navigate('/dashboard');
  }

  const handleLogout = async () => {
    const token = localStorage.getItem('vahan-token');
    // const userType = decryptData(localStorage.getItem("pair")); 
    const userType = localStorage.getItem('vahan-role');

    console.log('userType',userType)
    const API_URL = process.env.REACT_APP_API_URL; // Use your environment variable here
    try {
      if (token) {
        // Make an API call to logout based on user type
        if (userType === 'Super Admin' || userType === 'admin' || userType === 'transporter' || userType === 'computer-operator' || userType === 'guard') {
          await axios.post(`${API_URL}/api/truck/logout`, { userType: 'admin' }, {
            headers: { Authorization: `Bearer ${token}` }
          });
        } else if (userType === 'company') {
          await axios.post(`${API_URL}/api/truck/logout`, { userType: 'company' }, {
            headers: { Authorization: `Bearer ${token}` }
          });
        }
      }
  
      // Remove token from localStorage
      localStorage.removeItem('vahan-token');
      localStorage.removeItem('vahan-role');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  const encryptedRole = encryptData(role);
  localStorage.setItem("pair", encryptedRole);
  localStorage.setItem("twist", encryptData(username));
  localStorage.setItem("bhoot", encryptData(coins));
  localStorage.setItem("tang", encryptData(companyname));
  const handleHomeClick = () => {
    const storedRole = localStorage.getItem("pair");
    console.log('hahahaha',storedRole)
    const decryptedRole = storedRole ? decryptData(storedRole) : null;
    console.log('decryptedrole',decryptedRole)
    // Navigate to the actual dashboard based on role
    switch (decryptedRole) {
      case 'Super Admin':
        navigate('/superadmin-dashboard');
        break;
        case 'admin':
        navigate('/admin-dashboard');
        break;
      case 'Company':
        navigate('/company-dashboard');
        break;
        case 'computer-operator':
        navigate('/computer-operator-dashboard');
        break;
        case 'guard':
        navigate('/guard-dashboard');
        break;
        case 'transporter':
        navigate('/transporter-dashboard');
        break;
      case 'User':
        navigate('/user-dashboard'); // Update this path based on your actual route
        break;
      default:
        navigate('/'); // Default fallback
    }
  };

  const finalname = companyname ? companyname : username;

  return (
    <header className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white shadow-lg p-4 flex flex-col sm:flex-row justify-between items-center">
      {/* Left Section: Logo/Title */}
      <div className="flex items-center mb-2 sm:mb-0 sm:justify-center">
        <h1 className="text-2xl">{finalname}</h1>
        <button onClick={handleHomeClick} className="ml-4 bg-white text-blue-500 rounded px-2 py-1 hover:bg-gray-200 transition duration-300">
          Home
        </button>
        <button onClick={handleDash} className="ml-4 bg-white text-blue-500 rounded px-2 py-1 hover:bg-gray-200 transition duration-300">
          Dashboard
        </button>
      </div>

      {/* Center Section: User Info */}
      <div className="text-lg mb-2 sm:mb-0">
        <span className="text-transform: capitalize">{role}</span><br/>
        Coins: <span className="font-semibold">{coins}</span>
      </div>

      {/* Profile Dropdown Trigger with Username and Icon */}
      <div className="relative">
        <button
          onClick={toggleProfileMenu}
          className="flex items-center space-x-2 text-white hover:text-gray-200 transition duration-300"
        >
          <span>{username}</span>
          <svg
            className={`w-5 h-5 transform ${profileMenuOpen ? 'rotate-180' : ''} transition duration-300`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>

        {/* Dropdown Menu */}
        {profileMenuOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
            <div className="py-2">
              <div className="px-4 py-2 text-gray-900 font-semibold border-b border-gray-200">
                {username}
              </div>
              <a
                href="/profile"
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200 transition duration-300"
              >
                Profile
              </a>
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 transition duration-300"
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
