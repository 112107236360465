import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import CircularWeightDisplay from './CircularDisplay';

// Register required chart components with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const ChartComponent = ({ running, completed, monthlyData }) => {
  // Data for Pie Chart (Running vs Completed Vehicles)
  const pieData = {
    labels: ['Running Vehicles', 'Completed Vehicles'],
    datasets: [
      {
        label: '# of Vehicles',
        data: [running, completed],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
        hoverOffset: 10, // Create a nice "pop" effect when hovering
      },
    ],
  };

  // Calculate total net material weight
  // const totalNetMaterialWeight = monthlyData.reduce((total, data) => total + data.netMaterialWeight, 0);

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-center">Vehicle Status (Pie Chart)</h2>
      <div className="flex items-center justify-center w-full mt-4">
        <div className="w-40 h-40"> {/* Set the same width and height as CircularWeightDisplay */}
          <Pie 
            data={pieData} 
            options={{
              maintainAspectRatio: false,
              responsive: true,
              elements: {
                arc: {
                  borderWidth: 2, // Add border if desired
                },
              },
            }} 
          />
        </div>

        <div className="ml-8"> {/* Add margin for spacing */}
          <h2 className="text-center">Total Net Material Weight</h2>
          <CircularWeightDisplay totalNetMaterialWeight={monthlyData} />
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
