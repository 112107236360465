import React from 'react';

const CircularWeightDisplay = ({ totalNetMaterialWeight }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative w-40 h-40 rounded-full border-8 border-[#FF6384] flex items-center justify-center">
        <div className="absolute inset-0 rounded-full bg-[#FF6384] opacity-30"></div>
        <span className="text-xl font-bold text-white z-10">
          {totalNetMaterialWeight} Tons
        </span>
      </div>
    </div>
  );
};

export default CircularWeightDisplay;
