import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GuardTruckTable from './GuardTruckTable';
import Header from './Common/Header';
import { useNavigate } from 'react-router-dom'; 
import { encryptData } from './crypto-js/crypto';

const GuardDashboard = () => {
  const [filteredLoadings, setFilteredLoadings] = useState([]); // For storing the filtered truck loadings
  const [error, setError] = useState('');
  const [profile, setProfile] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit] = useState(10); // Set your items per page
  const [searchTerm, setSearchTerm] = useState(''); // For search term
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState(null);

  const navigate = useNavigate();

  const fetchTruckLoadingDetails = async () => {
    const token = localStorage.getItem('vahan-token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/truck/truck-loadings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: currentPage,
          limit,
          searchTerm,
        },
      });
      setFilteredLoadings(response.data.truckLoadings);
      setTotalPages(Math.ceil(response.data.total / limit));
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching truck loading details');
    }
  };
  const fetchLoggedinUserProfile = async () => {
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/getProfiles`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setProfile(response.data.getProfile); // Set the fetched users in the state
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching users');
    }
  };
  useEffect(() => {
    fetchTruckLoadingDetails(); 
    fetchLoggedinUserProfile();
  }, [currentPage, searchTerm]); // Dependency array includes currentPage and searchTerm

  const handleTruckClick = (truck) => {
    setSelectedTruck(truck);
    setIsModalOpen(true);
    const encryptedRole = encryptData(profile.role);
    localStorage.setItem("pair", encryptedRole);
    localStorage.setItem("twist", encryptData(profile?.username));
    localStorage.setItem("bhoot", encryptData(profile?.coins));
    localStorage.setItem("tang", encryptData(profile?.company?.name));
    navigate(`/truck-details/${truck}`);

  };

  const handleSearchChange = (term) => {
    setSearchTerm(term);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-4 w-full">
      <Header username={profile.username} role={profile.role} companyname={profile.company?.name} coins={profile.coins} />
      {error && <p className="text-red-500">{error}</p>}

      <GuardTruckTable
        truckLoadings={filteredLoadings}
        onViewDetails={handleTruckClick}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        onSearchChange={handleSearchChange} // Pass search change handler
      />

      {isModalOpen && (
        <div>
          <p>Truck Details for Vehicle: {selectedTruck?.vehicleNumber}</p>
        </div>
      )}
    </div>
  );
};

export default GuardDashboard;
