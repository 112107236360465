import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TruckLoadingTable from './TruckLoadingsTable';
import CreateUserModal from './CreateUserModal';
import { FaFileCsv, FaFileExcel, FaPrint } from 'react-icons/fa';
import Edit from './images/edit.svg';
import Delete from './images/delete.svg';
import Header from './Common/Header';
import TruckLoadingForm from './createtruckloading';
import CreateTruckLoad from './CreateTruckLoadModal';

const TransporterDashboard = () => {
  const [truckLoadings, setTruckLoadings] = useState([]); // State to hold truck loading data
  const [error, setError] = useState(''); // State for error messages
  const [newUser, setNewUser] = useState({ name: '', email: '' }); // State for new user data
  const [newTruckLoading, setNewTruckLoading] = useState({ details: '' }); // State for new truck loading data
  const [showCreateUserModal, setShowCreateUserModal] = useState(false); // State for showing/hiding the user modal
  const [users, setUsers] = useState([]);
  const [profile, setProfile] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for showing/hiding the truck loading modal

  // Fetch truck loading details
  const fetchTruckLoadingDetails = async () => {
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/truck/truck-loading`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setTruckLoadings(response.data); // Update state with fetched truck loadings
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching truck loading details');
    }
  };

  // Fetch users by company ID
  const fetchUsersByCompanyId = async () => {
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/getUsersByCompanyId`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setUsers(response.data); // Set the fetched users in the state
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching users');
    }
  };

  // Fetch logged-in user profile
  const fetchLoggedinUserProfile = async () => {
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/getProfiles`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setProfile(response.data.getProfile); // Set the fetched users in the state
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching users');
    }
  };

  // Create a new user
  const handleCreateUser = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/company/create-users`, newUser, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      window.location.reload();
      setNewUser({ name: '', email: '' }); // Reset the form
      setShowCreateUserModal(false); // Close the modal after successful user creation
      alert('user created successfully')
      window.location.reload();

    } catch (err) {
      alert(err.response?.data?.message || 'Error creating user')
      setError(err.response?.data?.message || 'Error creating user');
    }
  };
  const handleDelete = (id) => {
    alert("Delete Not allowed !!!! we are working on it.")
    // Add your delete logic here
    // console.log(`Delete loading with ID: ${id}`);
};
const handleEdit = (id) => {
  alert("Edit Not allowed !!!! we are working on it.")
  // Add your delete logic here
  // console.log(`Delete loading with ID: ${id}`);
};
  // Create new truck loading entry
  const handleCreateTruckLoading = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/truck/truck-loading`, newTruckLoading, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setNewTruckLoading({ details: '' }); // Reset the form
      setIsModalOpen(false); // Close the modal after successful creation
      fetchTruckLoadingDetails(); // Refresh truck loading details
    } catch (err) {
      setError(err.response?.data?.message || 'Error creating truck loading entry');
    }
  };

  // Effect to fetch truck loading details on component mount
  useEffect(() => {
    fetchTruckLoadingDetails();
    fetchUsersByCompanyId();
    fetchLoggedinUserProfile();
  }, []);

  return (
    <>
<div className="w-full mb-10">
<Header username={profile.username} coins={profile.coins} companyname={profile.company?.name} role={profile.role}/>
      {error && <p className="text-red-500 mb-4">{error}</p>} {/* Display error message */}

      {/* Display truck loading details */}
      <section>
  <TruckLoadingTable truckLoadings={truckLoadings} setIsModalOpen={setIsModalOpen} />
</section>



      {/* Button to open Create User modal */}
      {/* <section className="mb-8">
        <button
          className="px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold rounded-lg hover:from-blue-600 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-transform transform hover:scale-105 shadow-2xl shadow-blue-100"
          onClick={() => setShowCreateUserModal(true)}
        >
          Create New User
        </button>
      </section> */}

      {/* Button to open Create Truck Loading modal */}
    

      {/* Create Truck Loading Modal */}
      <CreateTruckLoad isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <TruckLoadingForm 
          newTruckLoading={newTruckLoading} // Pass the newTruckLoading state
          setNewTruckLoading={setNewTruckLoading} // Pass the function to update the newTruckLoading state
          handleCreateTruckLoading={handleCreateTruckLoading} // Pass the handler function
          onClose={() => setIsModalOpen(false)} // Function to close the modal
        />
      </CreateTruckLoad>

      {/* Create User Modal */}
      {showCreateUserModal && (
  <CreateUserModal
    newUser={newUser}
    setNewUser={setNewUser}
    handleCreateUser={handleCreateUser}
    onClose={() => setShowCreateUserModal(false)} // Function to close the modal
  />
)}


<div className="p-2 shadow-lg rounded-lg bg-white w-full mb-10">
  <div className='justify-between flex bg-slate-100 px-3 py-4 rounded-tl-md rounded-tr-md item-center '>
    <h2 className="text-3xl font-bold p-2 sm:p-2 items-center ">Company Users</h2>
    
    {/* Record */}
    <div className='flex item-center space-x-2 '>
      {/* Create New User Button */}
      <button
        onClick={() => setShowCreateUserModal(true)} // Open the modal on click
        className="flex items-center justify-center rounded-md p-2 bg-blue-500 text-white hover:bg-blue-600 transition"
      >
        Create New User
      </button>

      {/* Form Button */}
      <div className="flex flex-wrap justify-center items-center space-x-1 ">
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-yellow-200 transition">
          <FaFileCsv 
            className="text-yellow-500 sm:text-3xl text-xl" 
            title="Export as CSV" 
          />
        </div>
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-green-200 transition">
          <FaFileExcel 
            className="text-green-500 sm:text-3xl text-xl" 
            title="Export as Excel" 
          />
        </div>
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-gray-200 transition">
          <FaPrint 
            className="text-black-500 sm:text-3xl text-xl" 
            title="Print" 
          />
        </div>
      </div>
    </div>
  </div>

  {/* Record Table */}
  <div className="overflow-x-auto ">
    {users.length === 0 ? (
      <p className="text-center text-gray-500">No users found.</p> // Message if no users found
    ) : (
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => (
            <tr key={user._id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.username}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex space-x-2">
                <img src={Edit} alt="Edit" className="h-5 w-5 cursor-pointer" title="Edit" onClick={handleEdit}/>
                <img src={Delete} alt="Delete" className="h-5 w-5 cursor-pointer" title="Delete" onClick={handleDelete}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
</div>

    </div>
    </>
  );
};

export default TransporterDashboard;
