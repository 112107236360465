import React, { useRef } from 'react';

const CreateTruckLoad = ({ isOpen, onClose, children }) => {
  const modalRef = useRef(null);

  if (!isOpen) return null;

  // Handle closing the modal when clicking outside of it
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose(); // Close modal when clicking outside
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleClickOutside} // Attach click listener to the overlay
    >
      <div
  ref={modalRef} // Use ref to identify modal content
  className="bg-white rounded-lg p-6 shadow-lg w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%] xl:w-[30%] relative max-h-[80vh] overflow-y-auto "
>
<h1 className="text-2xl font-bold mb-4 flex justify-center">Truck Loading Form</h1>
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
        >
          &times;
        </button>
        
        {/* Modal content */}
        {children}
      </div>
    </div>
  );
};

export default CreateTruckLoad;
