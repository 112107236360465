import React, { useRef, useEffect } from "react";

const CreateUserModal = ({
  newUser,
  setNewUser,
  handleCreateUser,
  onClose,
}) => {
  const roles = [
    "admin",
    "driver",
    "transporter",
    "computer-operator",
    "guard",
  ]; // Available roles
  const modalRef = useRef(null);

  // Handle clicks outside the modal to close it
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose(); // Close modal when clicking outside
    }
  };

  // Attach event listener to detect outside clicks
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
      {/* Background overlay */}
      <div
        ref={modalRef}
        className="bg-white rounded-lg p-6 w-96 z-10 relative"
      >
        <h2 className="text-2xl font-semibold mb-4">Create New User</h2>

        <form onSubmit={handleCreateUser}>
          {/* Username field */}
          <input
            type="text"
            placeholder="Username"
            value={newUser.username}
            onChange={(e) =>
              setNewUser({ ...newUser, username: e.target.value })
            }
            required
            className="block w-full p-2 mb-4 border border-gray-300 rounded"
          />

          {/* Email field */}
          <input
            type="email"
            placeholder="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            required
            className="block w-full p-2 mb-4 border border-gray-300 rounded"
          />

          {/* Password field */}
          <input
            type="password"
            placeholder="Password"
            value={newUser.password}
            onChange={(e) =>
              setNewUser({ ...newUser, password: e.target.value })
            }
            required
            className="block w-full p-2 mb-4 border border-gray-300 rounded"
          />
          <input
            type="number"
            placeholder="Coins"
            value={newUser.coins}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              if (value >= 0 || e.target.value === "") {
                setNewUser({ ...newUser, coins: e.target.value });
              }
            }}
            required
            className="block w-full p-2 mb-4 border border-gray-300 rounded"
            min={0}
          />

          {/* Role field */}
          <select
            value={newUser.role}
            onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            required
            className="block w-full p-2 mb-4 border border-gray-300 rounded"
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role} value={role}>
                {role.charAt(0).toUpperCase() + role.slice(1)}
              </option>
            ))}
          </select>

          {/* Submit button */}
          <button
            type="submit"
            className="w-full p-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
          >
            Create User
          </button>
        </form>

        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 p-1 text-gray-400 hover:text-gray-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CreateUserModal;
