import React, { useState } from 'react';
import axios from 'axios';

const CommentModal = ({ isOpen, onClose, truckLoadingId }) => {
    const [comment, setComment] = useState('');
    const [reason, setReason] = useState('');

    if (!isOpen) return null;

    // Close modal on outside click
    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    // Handle form submission
    const handleSubmit = async () => {
        if (!comment || !reason) {
            alert("Please fill out all fields.");
            return;
        }

        try {
            const token = localStorage.getItem('vahan-token'); // Get the token
 const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/truck/${truckLoadingId}/comment`,
                { comment, reason },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            alert("Comment added successfully!");
            console.log('ressssssssssssss',response)
            onClose(); // Close the modal after submission
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    return (
        <div 
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            onClick={handleOverlayClick} // Handle click on the overlay
        >
            <div className="bg-white rounded-lg p-6 relative">
                <h2 className="text-xl font-bold mb-4">Comments</h2>
                <textarea 
                    className="w-full border rounded p-2 mb-4" 
                    placeholder="Add your comment..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)} // Update comment state
                ></textarea>
                
                {/* Radio buttons for feedback */}
                <div className="flex items-center mb-2">
                    <input 
                        id="incorrect-vehicle" 
                        type="radio" 
                        name="issue" 
                        value="Incorrect Vehicle Number"
                        checked={reason === "Incorrect Vehicle Number"}
                        onChange={(e) => setReason(e.target.value)} // Update reason state
                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500" 
                    />
                    <label htmlFor="incorrect-vehicle" className="ml-2 text-sm font-medium text-gray-900">Incorrect Vehicle Details</label>
                </div>
                <div className="flex items-center mb-2">
                    <input 
                        id="broken-seal" 
                        type="radio" 
                        name="issue" 
                        value="Broken Seal"
                        checked={reason === "Broken Seal"}
                        onChange={(e) => setReason(e.target.value)} // Update reason state
                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500" 
                    />
                    <label htmlFor="broken-seal" className="ml-2 text-sm font-medium text-gray-900">Broken Seal</label>
                </div>
                <div className="flex items-center mb-2">
                    <input 
                        id="weight" 
                        type="radio" 
                        name="issue" 
                        value="Weight"
                        checked={reason === "Weight"}
                        onChange={(e) => setReason(e.target.value)} // Update reason state
                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500" 
                    />
                    <label htmlFor="weight" className="ml-2 text-sm font-medium text-gray-900">Weight</label>
                </div>

                <button 
                    onClick={onClose} 
                    className="absolute top-2 right-2 text-red-600"
                >
                    &times;
                </button>
                <button 
                    onClick={handleSubmit} // Call submit function
                    className="mt-4 bg-blue-600 text-white rounded px-4 py-2"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default CommentModal;
