// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import ChartComponent from './chartComponent';
import axios from 'axios';
import { decryptData } from '../crypto-js/crypto';

const Dashboard = () => {
  const [running, setRunning] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [monthlyData, setMonthlyData] = useState([]);
  
  // Retrieve the token from local storage
  const token = localStorage.getItem('vahan-token');

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        console.error('Authorization token is missing.');
        return;
      }

      try {
        // Make API calls for both dashboards
        const [statusResponse, monthlyResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/truck/dashboard-data`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/truck/net-material-weight`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);
        // Set the data from the responses
        const { running, completed } = statusResponse.data;
        const  monthlyNetMaterialWeight = monthlyResponse.data;

        setRunning(running);
        setCompleted(completed);
        setMonthlyData(monthlyNetMaterialWeight.totalNetMaterialWeight);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token]); // Add token as a dependency
  const storedUsername = decryptData(localStorage.getItem("twist"));
  const storedCoins = decryptData(localStorage.getItem("bhoot"));
  const storedCompanyName = decryptData(localStorage.getItem("tang"));
  const storedRole = decryptData(localStorage.getItem("pair")); 
  return (
    <div className='w-full'>
             <Header username={storedUsername} coins={storedCoins} companyname={storedCompanyName } role={storedRole} />
             <div className="container mx-auto mt-8">
        <h1 className="text-2xl font-bold text-center mb-8">Dashboard</h1>

        {/* Pass the fetched data to the ChartComponent */}
        <ChartComponent running={running} completed={completed} monthlyData={monthlyData} />
      </div>
    </div>
  );
};

export default Dashboard;
