import React, { useState } from 'react';
import axios from 'axios';

const CreateCompanyForm = ({ onCompanyCreated }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('company'); // Default role set to "company"
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      const token = localStorage.getItem('vahan-token');
      const response = await axios.post(
        `${apiUrl}/api/company/create-company`,
        {
          name,
          email,
          password,
          role, // Added role in the form submission
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token in the Authorization header
          },
        }
      );
      onCompanyCreated(response.data); // Call parent callback to refresh company list
      alert('Company created successfully')
      window.location.reload();
      // Reset form fields
      setName('');
      setEmail('');
      setPassword('');
      setRole('company'); // Reset role to default
    } catch (err) {
      setError(err.response?.data?.message || 'Error creating company');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8 p-4 bg-white ">
    <h2 className="text-xl font-semibold mb-4 text-center">Create New Company</h2>
    {error && <p className="text-red-500 text-center">{error}</p>}
    
    {/* Company Name */}
    <div className="mb-4 ">
      <label className="block mb-2 font-semibold">Company Name</label>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        className="border border-gray-300 rounded-lg px-3 py-2 w-full sm:w-[300px"
      />
    </div>
    
   
    
    {/* Email */}
    <div className="mb-4">
      <label className="block mb-2 font-semibold">Email</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        className="border border-gray-300 rounded-lg px-3 py-2 w-full"
      />
    </div>
    
    {/* Password */}
    <div className="mb-4">
      <label className="block mb-2 font-semibold">Password</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        className="border border-gray-300 rounded-lg px-3 py-2 w-full"
      />
    </div>
    
    {/* Role */}
    <div className="mb-4">
      <label className="block mb-2 font-semibold">Role</label>
      <select
        value={role}
        onChange={(e) => setRole(e.target.value)}
        required
        className="border border-gray-300 rounded-lg px-3 py-2 w-full"
      >
        <option value="company">Company</option>
        <option value="admin">Admin</option>
        <option value="user">User</option>
        {/* Add any other roles as needed */}
      </select>
    </div>
    
    <button
      type="submit"
      className={`px-4 py-2 text-white rounded-lg w-full ${loading ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-600'}`}
      disabled={loading}
    >
      {loading ? 'Creating...' : 'Create Company'}
    </button>
  </form>
  
  );
};

export default CreateCompanyForm;
