import React, { useState, useRef } from 'react';

const CameraCapture = () => {
  const [cameraOn, setCameraOn] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const startCamera = async () => {
    setCameraOn(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (err) {
      console.error('Error accessing the camera: ', err);
    }
  };

  const takePicture = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const imageDataUrl = canvasRef.current.toDataURL('image/png'); // Get the image data URL
    setCapturedImage(imageDataUrl); // Save the captured image for later use
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setCameraOn(false);
    setCapturedImage(null); // Clear captured image when stopping the camera
  };

  return (
    <div>
      {!cameraOn && (
        <button
          onClick={startCamera}
          className="bg-blue-500 text-white px-4 py-2 rounded w-full sm:w-auto"
        >
          Open Camera
        </button>
      )}

      {cameraOn && (
        <div>
          <video ref={videoRef} width="640" height="480" autoPlay className="mb-4"></video>
          <canvas ref={canvasRef} width="640" height="480" style={{ display: 'none' }}></canvas>
          <button
            onClick={takePicture}
            className="bg-green-500 text-white px-4 py-2 rounded mr-2"
          >
            Capture Image
          </button>
          <button
            onClick={stopCamera}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Stop Camera
          </button>
        </div>
      )}

      {capturedImage && (
        <div>
          <h3>Captured Image:</h3>
          <img src={capturedImage} alt="Captured" className="mt-4" />
        </div>
      )}
    </div>
  );
};

export default CameraCapture;
