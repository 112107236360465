import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "./Modal";
import CreateCompanyForm from "./createCompanyForm"; // Ensure the correct filename casing
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { FaFileCsv, FaFileExcel, FaPlus, FaPrint } from "react-icons/fa";
import active from "./images/active.svg";
import Edit from "./images/edit.svg";
import Delete from "./images/delete.svg";
import SuperAdminTruckLoadingTable from "./supertruckloading";
import Header from "./Common/Header";

const socket = io(process.env.REACT_APP_API_URL, {
  transports: ["websocket"],
});

const SuperAdminDashboard = () => {
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [truckLoadings, setTruckLoadingDetails] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingTruckLoading, setLoadingTruckLoading] = useState(true);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("vahan-token");
    if (!token) {
      navigate("/");
    } else {
      fetchCompanies();
      fetchUsers();
      fetchTruckLoadingDetails();
    }

    socket.on("connect", () => {
      console.log("Socket connected:", socket.id); // Log when connected
    });

    // Set the socket event listeners
    socket.on("userCreated", handleUserCreated);
    socket.on("companyCreated", handleCompanyCreated);

    // Cleanup function to remove socket listeners on unmount
    return () => {
      socket.off("userCreated", handleUserCreated);
      socket.off("companyCreated", handleCompanyCreated);
    };
  }, [navigate]);

  // Move handleCompanyCreated outside the useEffect hook
  const handleCompanyCreated = (newCompany) => {
    setCompanies((prevCompanies) => {
      const companyExists = prevCompanies.some(
        (company) => company._id === newCompany._id
      );
      if (!companyExists) {
        return [...prevCompanies, newCompany];
      }
      return prevCompanies; // Return previous state if it exists
    });
  };

  const handleUserCreated = (newUser) => {
    setUsers((prevUsers) => [...prevUsers, newUser]);
  };

  const fetchCompanies = async () => {
    const token = localStorage.getItem("vahan-token");
    setLoadingCompanies(true); // Set loading state
    setError(""); // Clear previous error
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/company/companies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCompanies(response.data);
    } catch (err) {
      setError(err.response?.data?.message || "Error fetching companies");
    } finally {
      setLoadingCompanies(false);
    }
  };

  const fetchUsers = async () => {
    const token = localStorage.getItem("vahan-token");
    setLoadingUsers(true); // Set loading state
    setError(""); // Clear previous error
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/company/getUsers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(response.data);
    } catch (err) {
      setError(err.response?.data?.message || "Error fetching users");
    } finally {
      setLoadingUsers(false);
    }
  };

  const fetchTruckLoadingDetails = async () => {
    const token = localStorage.getItem("vahan-token");
    setLoadingTruckLoading(true); // Set loading state
    setError(""); // Clear previous error
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/truck/getAllTruckLoadingDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTruckLoadingDetails(response.data.truckLoadings);
    } catch (err) {
      setError(
        err.response?.data?.message || "Error fetching truck loading details"
      );
    } finally {
      setLoadingTruckLoading(false);
    }
  };
  return (
    <>
      <div className="p-2 shadow-lg rounded-lg bg-white w-full mb-10">
        <Header
          username={"Expert Solutions"}
          coins={"∞"}
          companyname={"Expert Solutions"}
          role={"Super Admin"}
        />
        <div className="justify-between flex  bg-slate-100 px-4 py-4 rounded-tl-md rounded-tr-md item-center ">
          <h2 className="text-3xl  font-bold p-4 items-center ">
            Users Details
          </h2>
          {/* Record */}
          <div className="flex    item-center space-x-2  ">
            {/* form Button */}

            <div className="flex flex-wrap justify-center items-center space-x-1 ">
              <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-yellow-200 transition">
                <FaFileCsv
                  className="text-yellow-500 sm:text-3xl text-xl"
                  title="Export as CSV"
                />
              </div>
              <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-green-200 transition">
                <FaFileExcel
                  className="text-green-500 sm:text-3xl text-xl"
                  title="Export as Excel"
                />
              </div>
              <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-gray-200 transition">
                <FaPrint
                  className="text-black-500 sm:text-3xl text-xl"
                  title="Print"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Record Table */}
        <div className="overflow-x-auto ">
          {loadingUsers ? (
            <p>Loading users...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : users.length > 0 ? (
            <table className="min-w-full text-left table-auto border-collapse border">
              <thead className="bg-slate-100 ">
                <tr className="border-b ">
                  <th className="px-4 py-4 text-gray-600 font-medium">
                    UserName
                  </th>
                  <th className="px-4 py-4 text-gray-600 font-medium">Email</th>
                  <th className="px-4 py-4 text-gray-600 font-medium">Role</th>
                  <th className="px-4 py-4 text-gray-600 font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user._id}
                    className="border-b odd:bg-white even:bg-gray-50"
                  >
                    <td className="px-4 py-4">{user.username}</td>
                    <td className="px-4 py-4">{user.email}</td>
                    <td className="px-4 py-4">{user.role}</td>
                    <td className="flex px-4 py-4">
                      <div className="w-10 mr-2">
                        <img src={active} className="w-11"alt="logo" />
                      </div>
                      <div className="w-10 mr-2">
                        <img src={Edit} className="w-11" alt="logo"/>
                      </div>
                      <div className="w-10 mr-2">
                        <img src={Delete} className="w-11"alt="logo" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No users found.</p>
          )}
        </div>
      </div>

      {/*Companies Details  */}
      <div className="p-2 shadow-lg rounded-lg bg-white w-full mb-10">
        <div className="justify-between flex  bg-slate-100 px-4 py-4 rounded-tl-md rounded-tr-md item-center ">
          <h2 className="text-3xl  font-bold p-4 items-center ">
            Companies Details
          </h2>
          {/* Record */}
          <div className="flex    item-center space-x-2  ">
            {/* form Button */}
            <div className="flex flex-wrap justify-center items-center space-x-1  ">
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-blue-500 text-white px-3 py-3 rounded-lg hover:bg-blue-600 font-semibold mt-3  mb-4"
              >
                <span className="hidden sm:inline">Create New Company</span>
                <FaPlus className="inline-block sm:ml-2" />
              </button>

              <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <CreateCompanyForm
                  onCompanyCreated={(companyData) => {
                    handleCompanyCreated(companyData);
                    setIsModalOpen(false);
                  }}
                />
              </Modal>
            </div>

            <div className="flex flex-wrap justify-center items-center space-x-1 ">
              <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-yellow-200 transition">
                <FaFileCsv
                  className="text-yellow-500 sm:text-3xl text-xl"
                  title="Export as CSV"
                />
              </div>
              <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-green-200 transition">
                <FaFileExcel
                  className="text-green-500 sm:text-3xl text-xl"
                  title="Export as Excel"
                />
              </div>
              <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-gray-200 transition">
                <FaPrint
                  className="text-black-500 sm:text-3xl text-xl"
                  title="Print"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Record Table */}
        <div className="overflow-x-auto ">
          {loadingCompanies ? (
            <p>Loading companies...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : companies.length > 0 ? (
            <table className="min-w-full text-left table-auto border-collapse border">
              <thead className="bg-slate-100 ">
                <tr className="border-b ">
                  <th className="px-4 py-4 text-gray-600 font-medium">
                    Company Name
                  </th>
                  <th className="px-4 py-4 text-gray-600 font-medium">
                    Company Email
                  </th>
                  <th className="px-4 py-4 text-gray-600 font-medium">Coins</th>
                  <th className="px-4 py-4 text-gray-600 font-medium">
                    Company Created Time
                  </th>
                  <th className="px-4 py-4 text-gray-600 font-medium">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company) => (
                  <tr
                    key={company._id}
                    className="border-b odd:bg-white even:bg-gray-50"
                  >
                    <td className="px-4 py-4">{company.name}</td>
                    <td className="px-4 py-4">{company.email}</td>
                    <td className="px-4 py-4">{company.coins}</td>
                    <td className="px-4 py-4">{company.createdAt}</td>
                    <td className="flex px-4 py-4">
                      <div className="w-10 mr-2">
                        <img src={active} className="w-11"alt="logo" />
                      </div>
                      <div className="w-10 mr-2">
                        <img src={Edit} className="w-11" alt="logo"/>
                      </div>
                      <div className="w-10 mr-2">
                        <img src={Delete} className="w-11"alt="logo" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No companies found.</p>
          )}
        </div>
      </div>

      {/*Truck Loading Details  */}
      <SuperAdminTruckLoadingTable truckLoadings={truckLoadings} />
    </>
  );
};

export default SuperAdminDashboard;
