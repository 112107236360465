import React from 'react';
import {FaFileCsv, FaFileExcel, FaPrint} from 'react-icons/fa'
const SuperAdminTruckLoadingTable = ({ truckLoadings }) => {
  return (
    <>
     <div className="p-2 shadow-lg rounded-lg bg-white w-full mb-10">
    <div className='justify-between flex  bg-slate-100 px-4 py-4 rounded-tl-md rounded-tr-md item-center '>
        <h2 className="text-3xl  font-bold p-4 items-center ">Truck Loading Details</h2>
    {/* Record */}
  <div className='flex    item-center space-x-2  '>

    {/* form Button */}

      <div className="flex flex-wrap justify-center items-center space-x-1 ">
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-yellow-200 transition">
          <FaFileCsv 
            className="text-yellow-500 sm:text-3xl text-xl" 
            title="Export as CSV" 
          />
        </div>
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-green-200 transition">
          <FaFileExcel 
            className="text-green-500 sm:text-3xl text-xl" 
            title="Export as Excel" 
          />
        </div>
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-gray-200 transition">
          <FaPrint 
            className="text-black-500 sm:text-3xl text-xl" 
            title="Print" 
          />
        </div>
      </div>

  </div>
    </div>
      
      {/* Record Table */}
      <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200 shadow-lg">
        <thead>
          <tr className="bg-gray-100 text-gray-700 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">ID</th>
            <th className="py-3 px-6 text-left">Details</th>
            <th className="py-3 px-6 text-left">Created By</th>
            <th className="py-3 px-6 text-left">Role</th>
            <th className="py-3 px-6 text-left">Created At</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {truckLoadings.length > 0 ? (
            truckLoadings.map((loading) => (
              <tr key={loading._id} className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6">{loading._id}</td>
                <td className="py-3 px-6">{loading.details}</td>
                <td className="py-3 px-6">{loading?.createdBy?.username}</td>
                <td className="py-3 px-6">{loading?.createdBy?.role}</td>
                <td className="py-3 px-6">
                  {new Date(loading.createdAt).toLocaleString()} {/* Formatting date */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="py-3 px-6 text-center text-gray-500">
                No truck loading details found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
      
    </div>
    </>
  );
};

export default SuperAdminTruckLoadingTable;
