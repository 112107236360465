import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import pic from "./images/truck-logistics-operation-dusk.jpg";
import logo from "./images/1 (1).svg"


const CompanyLogin = () => {
  const [email, setEmail] = useState(""); // State for the company email
  const [password, setPassword] = useState(""); // State for the password
  const [error, setError] = useState(""); // State for error messages
  const navigate = useNavigate(); // Initialize the navigate function

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/loginCompany`,
        {
          email,
          password,
        }
      );
      // Handle successful login
      if (response.data.token) {
        localStorage.setItem("vahan-token", response.data.token);
        localStorage.setItem("vahan-role", response.data.role);

        navigate("/company-dashboard"); // Redirect to the dashboard or any other page
      }
    } catch (err) {
      // Handle error response
      setError(err.response?.data?.message || "Login failed");
    }
  };

  return (
    <div className=" w-full bg-[#f3f4f2] ">
      <div className="w-full  flex items-center h-[90vh]">
        <div className=" w-full sm:w-1/2 h-full flex flex-col items-center justify-center p-5">
        <img src={logo} className="max-w-[100px]" alt="logo"/>
        <div className="w-full mt-9">
          <form onSubmit={handleSubmit} className="max-w-[720px]  mx-auto ">
            <h2 className="text-3xl  font-semibold mb-5">Company Login</h2>
            {error && <p className="text-red-500">{error}</p>}{" "}
            {/* Display error message */}
            <label className="block font-semibold text-gray-600">
              Company Email
            </label>
            <input
              type="email"
              placeholder="Company Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block border p-2 mt-2 mb-4 w-full  text-base font-medium"
              required
            />
            <label className="block font-semibold text-gray-600">
              Password
            </label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="block border p-2 mt-2 mb-4 w-full   text-base font-medium"
              required
            />
            <span
              className=" block w-full cursor-pointer mt-2 mb-2 text-sm font-semibold text-gray-400 text-right cursor-pointer"
            >
              forgot password ?
            </span>
            <button
              type="submit"
              className="bg-[#26a7c8] w-full  p-2 text-white rounded hover:bg-[#1f87a1] transition"
            >
              Login as Company
            </button>
          </form>
          </div>
          </div>
          <div className="hidden sm:block  w-1/2    h-full rounded-lg">
          
          <img
            src={pic}
            className=" bg-cover bg-center w-full h-full "
            alt="logo"
          />
        </div>
        
      </div>
    </div>
  );
};

export default CompanyLogin;
