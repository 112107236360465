import React from "react";
import logo from '../images/1 (1).svg'

const SelectRole = ({ selectedRole, setSelectedRole, options }) => {
  return (<div className="w-full flex items-center justify-between px-6 bg-[#f3f4f2] border-b-2">
    <div className=" flex items-center justify-center space-x-8   h-[10vh]">
      <h1 className="text-base sm:text-2xl  font-medium ">Select Role to Login</h1>
      <select
        className=" px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        value={selectedRole}
        onChange={(e) => setSelectedRole(e.target.value)}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
    <img src={logo} className="w-10 sm:w-16" alt="company logo"/>
    </div>
  );
};

export default SelectRole;
