import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TruckLoadingTable from './TruckLoadingsTable';
import CreateUserModal from './CreateUserModal'; // Importing CreateUserModal component
import Header from './Common/Header';
import {FaFileCsv, FaFileExcel, FaPrint } from 'react-icons/fa';
import Edit from './images/edit.svg'
import Delete from './images/delete.svg'
import TruckLoadingForm from './createtruckloading';
import CreateTruckLoad from './CreateTruckLoadModal';
import Select from 'react-select';
import { encryptData } from './crypto-js/crypto';
const CompanyDashboard = () => {
  const [truckLoadings, setTruckLoadings] = useState([]); // State to hold truck loading data
  const [error, setError] = useState(''); // State for error messages
  const [role, setRole] = useState('company');
  const [newUser, setNewUser] = useState({ name: '', email: '' }); // State for new user data
  const [newTruckLoading, setNewTruckLoading] = useState({ details: '' }); // State for new truck loading data
  const [permission, setPermission] = useState({ role: '', resource: 'truck-loading', permissions: {} }); // State for assigning permissions
  const [showCreateUserModal, setShowCreateUserModal] = useState(false); // State for showing/hiding the modal
  const [users, setUsers] = useState([]);
  const [availablePermission, setAvailablePermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

const [profile,setProfile] = useState([]);
const [user,setUser] = useState([]);
  // const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpens, setIsModalOpens] = useState(false);

  // Fetch truck loading details
  const fetchTruckLoadingDetails = async () => {
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/truck/truck-loadings`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setTruckLoadings(response.data); // Update state with fetched truck loadings
      setRole('company')
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching truck loading details');
    }
  };
  const fetchUsersByCompanyId = async () => {
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/getUsersByCompanyIds`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setUsers(response.data); // Set the fetched users in the state
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching users');
    }
  };
  const fetchLoggedinUserProfile = async () => {
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/getProfile`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setProfile(response.data.getProfile); // Set the fetched users in the state
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching users');
    }
  };
  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/permission/permissions`);
      setAvailablePermissions(response.data); // Set available permissions from API
    } catch (err) {
      console.error('Error fetching permissions:', err);
    }
  };
  const fetchUsers = async () => {
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/company/users`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setUser(response.data); // Set the fetched users in the state
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching users');
    }
  };
  // Create a new user
  const handleCreateUser = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/company/create-user`, newUser, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setNewUser({ name: '', email: '' }); // Reset the form
      setShowCreateUserModal(false); // Close the modal after successful user creation
      alert('user created successfully')
      window.location.reload();
    } catch (err) {
      alert(err.response?.data?.message || 'Error creating user')
      setError(err.response?.data?.message || 'Error creating user');
    }
  };

  const handleAssignPermission = async (e) => {
    e.preventDefault();
  
    try {
      // Find the selected user by username
      const selectedUser = users.find(user => user.username === permission.username);
      if (!selectedUser) {
        throw new Error('User not found');
      }
 
      // Prepare permissions array (map the selected permissions to their IDs)
      const permissionsArray = selectedPermissions
      .filter(perm => perm)  // Only include selected permissions
      .map(perm => perm.value);  // Map to permission IDs
    
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/permission/${selectedUser._id}/permissions`,
        { permissions: permissionsArray },  // Send the permission IDs
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('vahan-token')}`, // Add token here
          },
        }
      );
  
    } catch (err) {
      console.error(err);
      alert(err.response?.data?.error || 'Error assigning permissions');
    }
  };
  

  const options = availablePermission.map(permission => ({
    value: permission._id,
    label: permission.name,
  }));
  const handleChange = (selectedOptions) => {
    setSelectedPermissions(selectedOptions);
  };
  const encryptedRole = encryptData('Company');
  localStorage.setItem("pair", encryptedRole);
  localStorage.setItem("twist", encryptData(profile.username));
  localStorage.setItem("bhoot", encryptData(profile.coins));
  const handleDelete = (id) => {
    alert("Delete Not allowed !!!! we are working on it.")
    // Add your delete logic here
    // console.log(`Delete loading with ID: ${id}`);
};
const handleEdit = (id) => {
  alert("Edit Not allowed !!!! we are working on it.")
  // Add your delete logic here
  // console.log(`Delete loading with ID: ${id}`);
};
  // Create new truck loading entry
  const handleCreateTruckLoading = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const token = localStorage.getItem('vahan-token'); // Retrieve token from local storage

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/truck/truck-loading`, newTruckLoading, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the authorization header
        },
      });
      setNewTruckLoading({ details: '' }); // Reset the form
    } catch (err) {
      setError(err.response?.data?.message || 'Error creating truck loading entry');
    }
  };

  // Effect to fetch truck loading details on component mount
  useEffect(() => {
    fetchTruckLoadingDetails();
    fetchUsersByCompanyId();
    fetchLoggedinUserProfile();
    fetchUsers();
    fetchPermissions();
  }, []);
// ===============================
  return (
    <div className=" w-full mb-10">
    
       <Header username={profile.username ? profile.username:profile.name} coins={profile.coins} role={"Company"}/>
      {error && <p className="text-red-500 mb-4">{error}</p>} {/* Display error message */}

      {/* Display truck loading details */}
      <section className=''>
        {/* <h2 className="text-xl font-medium mb-2">Truck Loading Details</h2> */}
        
       
      <section>
      {role && <TruckLoadingTable truckLoadings={truckLoadings} setIsModalOpen={setIsModalOpen} roles={'company'} />}
      </section>

      {/* Modal for creating truck loading */}
      <CreateTruckLoad isOpen={isModalOpens} onClose={() => setIsModalOpens(false)}>
        <TruckLoadingForm
          newTruckLoading={newTruckLoading} // Pass the newTruckLoading state
          setNewTruckLoading={setNewTruckLoading} // Pass the function to update the newTruckLoading state
          handleCreateTruckLoading={handleCreateTruckLoading} // Pass the handler function
          
          onClose={() => setIsModalOpens(false)} // Function to close the modal
        />
      </CreateTruckLoad>
      </section>

      <section className="mb-8">
      <h2 className="text-2xl font-semibold mb-4">Assign Permission</h2>
      <form onSubmit={handleAssignPermission} className="bg-white p-6 rounded-lg shadow-lg">
        
        {/* User Selection */}
        <div className="mb-6">
          <label className="block text-lg font-medium mb-2" htmlFor="user-select">
            Select User
          </label>
          <select
            id="user-select"
            value={permission.username}
            onChange={(e) => setPermission({ ...permission, username: e.target.value })}
            required
            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
          >
            <option value="">Select User</option>
            {users.map((user) => (
              <option key={user._id} value={user.username}>
                {user.username}
              </option>
            ))}
          </select>
        </div>

        {/* Permissions */}
        <Select
      isMulti
      options={options}
      value={selectedPermissions}
      onChange={handleChange}
      placeholder="Select permissions..."
      className='mb-5'
    />


        {/* Submit Button */}
        <button
          type="submit"
          className="w-full p-3 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-all"
        >
          Assign Permission
        </button>
      </form>
    </section>

      {showCreateUserModal && (
        <CreateUserModal
          newUser={newUser}
          setNewUser={setNewUser}
          handleCreateUser={handleCreateUser}
          onClose={() => setShowCreateUserModal(false)}
        />
      )}
<section>

<div className="p-2 shadow-lg rounded-lg bg-white w-full mb-10">
  <div className='justify-between flex bg-slate-100 px-3 py-4 rounded-tl-md rounded-tr-md item-center '>
    <h2 className="text-3xl font-bold p-2 sm:p-2 items-center ">Company Users</h2>
    
    {/* Record */}
    <div className='flex item-center space-x-2 '>
      {/* Create New User Button */}
      <button
        onClick={() => setShowCreateUserModal(true)} // Open the modal on click
        className="flex items-center justify-center rounded-md p-2 bg-blue-500 text-white hover:bg-blue-600 transition"
      >
        Create New User
      </button>

      {/* Form Button */}
      <div className="flex flex-wrap justify-center items-center space-x-1 ">
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-yellow-200 transition">
          <FaFileCsv 
            className="text-yellow-500 sm:text-3xl text-xl" 
            title="Export as CSV" 
          />
        </div>
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-green-200 transition">
          <FaFileExcel 
            className="text-green-500 sm:text-3xl text-xl" 
            title="Export as Excel" 
          />
        </div>
        <div className="flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-gray-200 transition">
          <FaPrint 
            className="text-black-500 sm:text-3xl text-xl" 
            title="Print" 
          />
        </div>
      </div>
    </div>
  </div>

  {/* Record Table */}
  <div className="overflow-x-auto ">
    {users.length === 0 ? (
      <p className="text-center text-gray-500">No users found.</p> // Message if no users found
    ) : (
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => (
            <tr key={user._id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.username}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.role}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex space-x-2">
                <img src={Edit} alt="Edit" className="h-5 w-5 cursor-pointer" title="Edit" onClick={handleEdit}/>
                <img src={Delete} alt="Delete" className="h-5 w-5 cursor-pointer" title="Delete" onClick={handleDelete}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
</div>

</section>

    </div>
   
  );
};

export default CompanyDashboard;
