// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDh7KPmwjq5UuqkEMfKfv-KwWLJ9S5S2Cw",
  authDomain: "tactical-attic-431812-f0.firebaseapp.com",
  projectId: "tactical-attic-431812-f0",
  storageBucket: "tactical-attic-431812-f0.appspot.com",
  messagingSenderId: "549107136190",
  appId: "1:549107136190:web:683951143ef76f00f250ef",
  measurementId: "G-ZB81C9ZZXQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);  // Correctly initialize analytics
const messaging = getMessaging(app);  // Initialize messaging

export { messaging, getToken, onMessage };  // Export messaging
