import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SuperAdminLogin from "./components/superAdminLogin";
import CompanyLogin from "./components/CompanyLogin";
import UserLogin from "./components/UserLogin";
import SuperAdminDashboard from "./components/SuperAdminDashboard";
import CompanyDashboard from "./components/CompanyDashbord.js";
import AdminDashboard from "./components/AdminDashboard.js";
import TransporterDashboard from "./components/TransporterDashboard.js";
import ComputerOperatorDashboard from "./components/ComputerOperatorDashboard.js";
import Superadmintruck from './components/supertruckloading.js';
import CreatetruckloadModal from "./components/createtruckloading.js";
import PrivateRoute from "./PrivateRoute.js";
import SelectRole from "./components/Common/SelectRole.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import GuardDashboard from "./components/GuardDashboard.js";
import TruckDetails from "./components/TruckDetails/TruckDetails.js";
import { messaging, getToken, onMessage } from './firebase.js';

const App = () => {
  const [selectedRole, setSelectedRole] = useState("Super Admin");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('vahan-token');
    if (!token) {
      navigate('/');
    }

    const requestForToken = async () => {
      try {
        const token = await getToken(messaging, {
          vapidKey: 'YOUR_VAPID_PUBLIC_KEY',
        });
        console.log('FCM Token:', token);
        if (token) {
          localStorage.setItem('fcm-token', token);
        }
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    requestForToken();

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Replace alert with a more user-friendly notification method
      alert(`New message: ${payload.notification.title}`);
    });
  }, [navigate]);

  const roleOptions = ["Super Admin", "Company", "User"];
  const renderLoginForm = () => {
    switch (selectedRole) {
      case "Super Admin":
        return <SuperAdminLogin />;
      case "Company":
        return <CompanyLogin />;
      case "User":
        return <UserLogin />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {location.pathname === "/" && (
        <SelectRole
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          options={roleOptions}
        />
      )}

      <Routes>
        <Route path="/" element={renderLoginForm()} />
        <Route path="/superadmin-dashboard" element={<PrivateRoute><SuperAdminDashboard /></PrivateRoute>} />
        <Route path="/company-dashboard" element={<PrivateRoute><CompanyDashboard /></PrivateRoute>} />
        <Route path="/computer-operator-dashboard" element={<PrivateRoute><ComputerOperatorDashboard /></PrivateRoute>} />
        <Route path="/admin-dashboard" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
        <Route path="/transporter-dashboard" element={<PrivateRoute><TransporterDashboard /></PrivateRoute>} />
        <Route path="/super-admin-truckloading" element={<PrivateRoute><Superadmintruck /></PrivateRoute>} />
        <Route path="/Create-truck-load" element={<PrivateRoute><CreatetruckloadModal /></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/guard-dashboard" element={<PrivateRoute><GuardDashboard /></PrivateRoute>} />
        <Route path="/truck-details/:id" element={<PrivateRoute><TruckDetails /></PrivateRoute>} />
      </Routes>
    </div>
  );
};

// Wrap App in Router to enable routing
const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
