import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import pic from "./images/truck-logistics-operation-dusk.jpg";
import logo from "./images/1 (1).svg"


const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Initialize the navigate function
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    setLoading(true); // Set loading to true

    try {
      // Send login request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/user-login`,
        {
          email,
          password,
        }
      );

      if (response.data.token) {
        // Save token and role to local storage
        localStorage.setItem("vahan-token", response.data.token);
        localStorage.setItem("vahan-role", response.data.role);

        // Navigate based on user role
        switch (response.data.role) {
          case "admin":
            navigate("/admin-dashboard"); // Redirect to Admin Dashboard
            break;
          case "transporter":
            navigate("/transporter-dashboard"); // Redirect to Transporter Dashboard
            break;
          case "computer-operator":
            navigate("/computer-operator-dashboard"); // Redirect to Computer Operator Dashboard
            break;
          case "driver":
            navigate("/driver-dashboard"); // Redirect to Driver Dashboard
            break;
            case "guard":
            navigate("/guard-dashboard"); // Redirect to Driver Dashboard
            break;
          default:
            navigate("/user-dashboard"); // Default redirect
        }

        setSuccess(response.data.message);
      }
    } catch (error) {
      // Handle login failure
      setError(error.response?.data?.message || "Login failed");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className=" w-full    ">
      <div className="w-full  flex items-center h-[90vh]">
        <div className=" w-full h-full sm:w-1/2  flex flex-col items-center justify-center p-5 ">
        <img src={logo} className="max-w-[100px]" alt="logo"/>
        <div className="w-full mt-9">
          <form onSubmit={handleSubmit} className="max-1 (1).svgw-[720px]  mx-auto">
            <h2 className="text-3xl  font-semibold mb-5">User Login</h2>

            {/* Email input field */}
            <label className="block font-semibold text-gray-600">
              User Email
            </label>
            <input
              type="email"
              placeholder="User Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Update state on input change
              required
              className=" block border  rounded p-2 mt-2 mb-4 w-full  text-base font-medium focus:bg-white outline-1 "
            />

            {/* Password input field */}
            <label className="block font-semibold text-gray-600">
              Password
            </label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Update state on input change
              required
              className="block border rounded p-2 mt-2 mb-4 w-full   text-base font-medium focus:bg-white outline-1 "
            />

            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}

            {/* Submit button */}
            <span
              className=" block w-full cursor-pointer mt-2 mb-2 text-sm font-semibold text-gray-400 text-right "
            >
              forgot password ?
            </span>
            <button
              type="submit"
              disabled={loading} // Disable button while loading
              className="bg-[#26a7c8] w-full  p-2 text-white rounded hover:bg-[#1f87a1] transition"
            >
              {loading ? "Logging in..." : "Login as User"}{" "}
              {/* Show loading text */}
            </button>
          </form>
          </div>
        </div>
        <div className="hidden sm:block  w-1/2    h-full rounded-lg">
          
          <img
            src={pic}
            className=" bg-cover bg-center w-full h-full "
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
